.navs_aside {
  background-color: #fff;
  display: flex !important;
}

.nav___item {
  width: 100%;
  .tab_link {
    text-align: left;
    justify-content: left;
  }
}

.tab_link {
  border-radius: 0 !important;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 0px;
  color: #000 !important;
  height: 45px;
  width: 100%;
  text-align: left;
  display: flex !important;
  align-items: center;
  padding-left: 28px !important;
  &:hover {
    color: #000 !important;
  }
  a {
    padding: 0;
  }
  &.active {
    background-color: var(--main-color) !important;
    color: #fff !important;
  }
}

.sticky {
  z-index: 100;
}

.css-yk16xz-control,
.css-1pahdxg-control,
.css-1s2u09g-control {
  border-radius: 0 !important;
  height: 45px;
  box-shadow: none !important;
}

.css-1uccc91-singleValue {
  font-size: 16px !important;
  border-radius: 0 !important;
  height: 45px;
  box-shadow: none !important;
}

.chip,
.highlightOption {
  background: #3aaa35 !important;
}

.multiSelectContainer li:hover {
  background: #3aaa35 !important;
}

.searchWrapperSubCategory .searchWrapper {
  border: 0px;
  padding: 0px;
}

.submit__btn {
  z-index: 1 !important;
}

.add-project__submit-button {
  color: #fff;
  background: #3aaa35 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit__btn.footer {
  z-index: 1 !important;
}
