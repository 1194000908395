.mode_style {
    border: 1px solid #C2C2C2;
    padding: 20px ;
    text-align: center;
    cursor: pointer;
    &.active {
        border: 1px solid #3aaa35;
    }
}
.card_title {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.cursor-pointer {
    cursor: pointer;
    transition: all 0.3s linear;
    svg {
        transition: all 0.3s linear;
        path {
            transition: all 0.3s linear;
        }
    }
    &:hover {
        background-color: #3aaa35;
        color: #fff;
        p {
            transition: all 0.3s linear;
            color: #fff;
        }
        svg {
            
            path {
                transition: all 0.3s linear;
                stroke: #fff;
            }
        }
    }
}

.dragable_btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 51.5px;
    border-radius: calc(0.25rem - 1px)  0 0 calc(0.25rem - 1px) ;
    background-color: transparent;
    transition: all 0.3s linear;
    z-index: 9;
    padding-bottom: 8px;
    border: none;
    outline: none;
    &:hover {
        background-color: #e0e0e0;
    }
}
.delete_btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 51.5px;
    border-radius: calc(0.25rem - 1px)  0 0 calc(0.25rem - 1px) ;
    background-color: transparent;
    transition: all 0.3s linear;
    z-index: 9;
    padding-right: 8px;
    padding-bottom: 8px;
    border: none;
    outline: none;
}

.ql-editor {
    min-height: 150px;
}