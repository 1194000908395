.navs_aside {
  background-color: #fff;
  display: flex !important;
}

.nav___item {
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  &:last-of-type {
    border-bottom: 1px solid transparent;
  }
  .tab_link {
    text-align: left;
    justify-content: left;
  }
}

.tab_link {
  border-radius: 0 !important;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 0px;
  color: #000 !important;
  height: 45px;
  width: 100%;
  text-align: left;
  display: flex !important;
  align-items: center;
  padding-left: 28px !important;
  &:hover {
    color: #000 !important;
  }
  a {
    padding: 0;
  }
  &.active {
    background-color: var(--main-color) !important;
    color: #fff !important;
  }
}

.sticky {
  z-index: 100;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  border-radius: 0 !important;
  height: 45px;
  box-shadow: none !important;
}

.css-1uccc91-singleValue {
  font-size: 16px !important;
}
.ril-image-current.ril__image {
  object-fit: contain;
  height: 600px !important;
}

.svg_img {
  svg {
    width: 40px;
    display: block;
  }
}

.sticky {
  top: unset !important;
}
