.email_shot_container {
    // width: 600px ;
    margin: 20px auto !important;
    .edit_mail{
        width: 120px;
        height: 45px;
        border: none;
        background-color: #26E71E;
        color: #000000;
        font-weight: bold;

        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        float: right;
    }
    .email_head {
        margin-top: 20px;
        padding: 40px 30px ;
        .email_title {
            color: #26E71E !important;
            line-height: 16px;
            width: 80%;
            text-align: center;
            margin: 0px auto;
            margin-bottom: 20px;
            font-size: 25px !important;
            line-height: 30px !important;
            margin-top: 40px;
        }
        .email_ver {
            color: #000 !important;
        font-weight: bold;

            line-height: 19px;
            width: 80%;
            text-align: center;
            margin: 0px auto;
            font-size: 16px;
            margin-bottom: 25px;
            margin-top: 20px;
            line-height: 10px !important;
        }
        img {
            width: 45%;
            margin: 20px auto;
            margin-top: 0;
        }
        .email_code {
            display: block;
            margin: 0 auto;
            width: 238px;
            height: 50px;
            border-radius: 50px;
            background-color: rgba(215, 233, 219, 0.53);
            border:1px solid #26E71E;
            color: #26E71E;
            font-weight: 500;
            font-size: 25px;
        }
    }
    .email_s {
        background-color: #26E71E;
        height: 100px;
        color: #000000;
        font-weight: bold;
        .e_social {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto;
            margin-bottom: 0;
            gap: 30px;
            font-size: 18px;
        }
    }
    .email_btns {
        img {
            display: block;
            width: 90%;
            margin: 20px auto;
            padding-top: 0;
            margin-top: 0;
        }
    }
    .order_list {
        padding: 20px 40px;
        border-bottom: 1px solid rgb(216, 215, 215);
        background-color: #fff;
        .title {
            color: #000;
            font-size: 20px;
            font-weight: 600;
        }
    }
    .email_ads {
        img{
            width: 100%;
        }
    }
    .email_foot {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EDEFF4;
        p{
            text-align: center;
            width: 100%;
            padding: 20px 20px;
            margin: 0;
            font-size: 12px;
        }
    }
}